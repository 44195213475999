import { Icon } from 'components';
import { Roles } from 'configs/roles';
// import { MainLayout } from 'layouts';
import MainLayout from 'layouts/Main';
import React, { lazy } from 'react';

import { MainRouteKeys } from '../enums';
import { MainRouteObject } from '../types';

const DashboardPage = lazy(() => import('pages/Dashboard'));
const DashboardReportByAgencyPage = lazy(() => import('pages/Dashboard/ReportByAgency/list'));
const DashboardReportByPCPage = lazy(() => import('pages/Dashboard/ReportByPC/list'));
const UsersPage = lazy(() => import('pages/Users'));
// const TransferPage = lazy(() => import('pages/transfers'));
const PersonalPage = lazy(() => import('pages/Personal'));
const PersonalInfoPage = lazy(() => import('pages/Personal/Info'));
const PersonalChangePasswordPage = lazy(() => import('pages/Personal/ChangePassword'));
const PersonalBalancePage = lazy(() => import('pages/Personal/Balance'));
const BalanceManagementPage = lazy(() => import('pages/Personal/Balance/Manage'));
const BalanceTransferPage = lazy(() => import('pages/Personal/Balance/Transfer'));
const PersonalLogoutPage = lazy(() => import('pages/Personal/Logout'));
const PayBillPage = lazy(() => import('pages/PayBill'));
const PayBillElectricPage = lazy(() => import('pages/PayBill/Electric/List'));
const PayBillElectricDetailsPage = lazy(() => import('pages/PayBill/Electric/Details'));
const TransactionsPage = lazy(() => import('pages/Transactions'));
const TransactionOrdersPage = lazy(() => import('pages/Transactions/Orders/List'));
const TransactionOrderDetailsPage = lazy(() => import('pages/Transactions/Orders/Details'));
const TopUpPointsPage = lazy(() => import('pages/top-up-points'));
const UpdateUser = lazy(() => import('pages/update-user'));
const CustomersPage = lazy(() => import('pages/customers'));
const PointTransactionsPage = lazy(() => import('pages/point-transactions'));
const OrdersPage = lazy(() => import('pages/orders'));
const TransactionIDBanksPage = lazy(() => import('pages/TransactionsBank'));

export const MainRoutesConfig: MainRouteObject = {
  [MainRouteKeys.Root]: {
    path: MainRouteKeys.Root,
    name: 'Trang chủ',
    title: 'Trang chủ',
    element: <MainLayout />,
    redirectUrl: MainRouteKeys.Personal,
    icon: <Icon.User />,
    roles: '*',
  },
  [MainRouteKeys.Personal]: {
    path: MainRouteKeys.Personal,
    name: 'Tài khoản cá nhân',
    title: 'Tài khoản cá nhân ',
    element: <PersonalPage />,
    redirectUrl: MainRouteKeys.PersonalInfo,
    icon: <Icon.Profile />,
    roles: '*',
  },
  [MainRouteKeys.PersonalInfo]: {
    path: MainRouteKeys.PersonalInfo,
    name: 'Xem thông tin',
    title: 'Xem thông tin ',
    element: <PersonalInfoPage />,
    icon: <Icon.Data />,
    roles: '*',
  },
  [MainRouteKeys.PersonalChangePassword]: {
    path: MainRouteKeys.PersonalChangePassword,
    name: 'Thay đổi mật khẩu',
    title: 'Thay đổi mật khẩu ',
    element: <PersonalChangePasswordPage />,
    icon: <Icon.ChangePasswordIcon />,
    roles: '*',
  },
  [MainRouteKeys.PersonalBalance]: {
    path: MainRouteKeys.PersonalBalance,
    name: 'Quản lý số dư',
    title: 'Quản lý số dư ',
    element: <PersonalBalancePage />,
    redirectUrl: MainRouteKeys.Balance,
    icon: <Icon.Wallet />,
    roles: '*',
  },
  [MainRouteKeys.PersonalLogout]: {
    path: MainRouteKeys.PersonalLogout,
    name: 'Đăng xuất',
    title: 'Đăng xuất ',
    element: <PersonalLogoutPage />,
    icon: <Icon.Logout />,
    roles: '*',
  },
  [MainRouteKeys.Dashboard]: {
    path: MainRouteKeys.Dashboard,
    name: 'Báo cáo quản trị',
    title: 'Báo cáo quản trị',
    element: <DashboardPage />,
    redirectUrl: MainRouteKeys.DashboardReportByPC,
    icon: <Icon.Dashboard />,
    roles: [Roles.ADMIN, Roles.SPVISOR],
  },
  [MainRouteKeys.DashboardReportByPC]: {
    path: MainRouteKeys.DashboardReportByPC,
    name: 'Xem theo PC',
    title: 'Xem theo PC',
    element: <DashboardReportByPCPage />,
    icon: <Icon.Dashboard />,
    roles: [Roles.ADMIN, Roles.SPVISOR],
  },
  [MainRouteKeys.DashboardReportByAgency]: {
    path: MainRouteKeys.DashboardReportByAgency,
    name: 'Xem theo điện lực',
    title: 'Xem theo điện lực',
    element: <DashboardReportByAgencyPage />,
    icon: <Icon.Dashboard />,
    roles: [Roles.ADMIN, Roles.SPVISOR],
  },
  [MainRouteKeys.Users]: {
    path: MainRouteKeys.Users,
    name: 'Quản lý người dùng',
    title: 'Quản lý người dùng',
    element: <UsersPage />,
    icon: <Icon.User />,
    roles: [Roles.ADMIN, Roles.LEADER, Roles.SPVISOR],
  },
  [MainRouteKeys.UpdateUser]: {
    path: MainRouteKeys.UpdateUser,
    name: 'Cập nhật người dùng',
    title: 'Cập nhật người dùng',
    element: <UpdateUser />,
    icon: <Icon.User />,
    roles: [Roles.ADMIN],
  },
  [MainRouteKeys.Balance]: {
    path: MainRouteKeys.Balance,
    name: 'Quản lý số dư',
    title: 'Quản lý số dư',
    element: <PersonalBalancePage />,
    redirectUrl: MainRouteKeys.BalanceManage,
    icon: <Icon.Wallet />,
    roles: '*',
  },
  [MainRouteKeys.BalanceManage]: {
    path: MainRouteKeys.BalanceManage,
    name: 'Quản lý số dư',
    title: 'Quản lý số dư',
    element: <BalanceManagementPage />,
    icon: <Icon.CoinExchange />,
    roles: '*',
  },
  [MainRouteKeys.Transfers]: {
    path: MainRouteKeys.Transfers,
    name: 'Chuyển điểm',
    title: 'Chuyển điểm',
    element: <BalanceTransferPage />,
    icon: <Icon.CoinExchange />,
    roles: '*',
  },
  [MainRouteKeys.TopUp]: {
    path: MainRouteKeys.TopUp,
    name: 'Nạp điểm',
    title: 'Nạp điểm',
    element: <TopUpPointsPage />,
    icon: <Icon.BalanceIcon />,
    roles: '*',
  },
  [MainRouteKeys.PayBill]: {
    path: MainRouteKeys.PayBill,
    name: 'Thanh toán hóa đơn',
    title: 'Thanh toán hóa đơn',
    element: <PayBillPage />,
    redirectUrl: MainRouteKeys.PayBillElectric,
    icon: <Icon.BalanceIcon />,
    roles: '*',
  },
  [MainRouteKeys.PayBillElectric]: {
    path: `${MainRouteKeys.PayBillElectric}`,
    name: 'Hoá đơn điện',
    title: 'Hoá đơn điện',
    element: <PayBillElectricPage />,
    icon: <Icon.User />,
    roles: '*',
  },
  [MainRouteKeys.PayBillElectricDetails]: {
    path: MainRouteKeys.PayBillElectricDetails,
    name: 'Thông tin đơn hàng',
    title: 'Thông tin đơn hàng',
    element: <PayBillElectricDetailsPage />,
    icon: <Icon.User />,
    roles: '*',
  },
  [MainRouteKeys.Transactions]: {
    path: MainRouteKeys.Transactions,
    name: 'Quản lý giao dịch',
    title: 'Quản lý giao dịch',
    element: <TransactionsPage />,
    redirectUrl: MainRouteKeys.TransactionBills,
    icon: <Icon.User />,
    roles: '*',
  },
  [MainRouteKeys.TransactionBills]: {
    path: MainRouteKeys.TransactionBills,
    name: 'Thanh toán hóa đơn',
    title: 'Thanh toán hóa đơn',
    element: <TransactionOrdersPage />,
    icon: <Icon.User />,
    roles: '*',
  },
  [MainRouteKeys.TransactionBillDetails]: {
    path: MainRouteKeys.TransactionBillDetails,
    name: 'Thông tin đơn hàng',
    title: 'Thông tin đơn hàng',
    element: <TransactionOrderDetailsPage />,
    icon: <Icon.User />,
    roles: '*',
  },
  [MainRouteKeys.Customers]: {
    path: MainRouteKeys.Customers,
    name: 'Quản lý khách hàng',
    title: 'Quản lý khách hàng',
    element: <CustomersPage />,
    icon: <Icon.Account />,
    roles: [Roles.ADMIN, Roles.SPVISOR],
  },
  [MainRouteKeys.PointTransactions]: {
    path: MainRouteKeys.PointTransactions,
    name: 'Cộng/trừ điểm',
    title: 'Cộng/trừ điểm',
    element: <PointTransactionsPage />,
    icon: <Icon.Account />,
    roles: '*',
  },
  [MainRouteKeys.Orders]: {
    path: MainRouteKeys.Orders,
    name: 'Quản lý đơn hàng',
    title: 'Quản lý đơn hàng',
    element: <OrdersPage />,
    icon: <Icon.Account />,
    roles: '*',
  },
  [MainRouteKeys.TransactionsBank]: {
    path: MainRouteKeys.TransactionsBank,
    name: 'Giao dịch ngân hàng',
    title: 'Giao dịch ngân hàng',
    element: <TransactionIDBanksPage />,
    icon: <Icon.Wallet />,
    roles: [Roles.ADMIN, Roles.SPVISOR],
  }
};
