export enum AuthRouteKeys {
  Root = '/auth',
  Login = '/auth/login',
  OTP = '/auth/otp',
  NewPassword = '/auth/new-password',
}

export enum ErrorRouteKeys {
  Root = '/error',
  NotFound = '/error/404',
  Internal = '/error/500',
  NotAccess = '/error/403',
}

export enum MainRouteKeys {
  Root = '/',
  Personal = '/personal',
  PersonalInfo = '/personal/info',
  PersonalBalance = '/personal/balance',
  PersonalChangePassword = '/personal/change-password',
  PersonalLogout = '/personal/logout',
  Dashboard = '/dashboard',
  DashboardReportByAgency = '/dashboard/report-by-agency',
  DashboardReportByPC = '/dashboard/report-by-pc',
  Users = '/users',
  UpdateUser = '/update-user',
  Balance = '/balance',
  BalanceManage = '/balance/manage',
  Transfers = '/balance/transfers',
  TopUp = '/balance/topup',
  PayBill = '/pay-bill',
  PayBillElectric = '/pay-bill/electric',
  PayBillElectricDetails = '/pay-bill/electric/:orderCode',
  Transactions = '/transactions',
  TransactionBills = '/transactions/bills',
  TransactionBillDetails = '/transactions/bills/:transactionCode',
  Customers = '/customers',
  PointTransactions = '/transactions/points',
  Orders = '/orders',
  TransactionsBank = '/transactionsbank',
}
