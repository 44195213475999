import { useMutation } from '@tanstack/react-query';
import { UserWalletManageService } from 'services/openapi';

type CreateUserMutationVariables = {
  phoneNumber: string;
  fullName: string;
  email?: string;
  role: 'Agency' | 'Leader' | 'SpVisor';
  compId: string;
  agencyId: string;
  isActive: boolean;
  accountNumber: string;
};

export const useCreateUserMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    any,
    Error,
    CreateUserMutationVariables
  >({
    mutationFn: (variables: CreateUserMutationVariables) => {
      return UserWalletManageService.userManageControllerCreateUser(variables);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};
