/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePinCodeDto } from '../models/ChangePinCodeDto';
import type { ChangePinCodeEntity } from '../models/ChangePinCodeEntity';
import type { CheckPhoneNumberEntity } from '../models/CheckPhoneNumberEntity';
import type { CheckPinCodeDto } from '../models/CheckPinCodeDto';
import type { CheckPinCodeEntity } from '../models/CheckPinCodeEntity';
import type { CreatePinCodeDto } from '../models/CreatePinCodeDto';
import type { CreatePinCodeEntity } from '../models/CreatePinCodeEntity';
import type { LoginDto } from '../models/LoginDto';
import type { LoginEntity } from '../models/LoginEntity';
import type { LogoutUserEntity } from '../models/LogoutUserEntity';
import type { ProfileUserEntity } from '../models/ProfileUserEntity';
import type { UpdateProfileUserDto } from '../models/UpdateProfileUserDto';
import type { UpdateProfileUserEntity } from '../models/UpdateProfileUserEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserWalletAuthService {

    /**
     * @param phoneNumber 
     * @returns CheckPhoneNumberEntity 
     * @throws ApiError
     */
    public static authControllerCheckPhoneNumber(
phoneNumber: string,
): CancelablePromise<CheckPhoneNumberEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/auth/check-phone-number/{phoneNumber}',
            path: {
                'phoneNumber': phoneNumber,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CreatePinCodeEntity 
     * @throws ApiError
     */
    public static authControllerCreatePinCode(
requestBody: CreatePinCodeDto,
): CancelablePromise<CreatePinCodeEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/auth/create-pin-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ChangePinCodeEntity 
     * @throws ApiError
     */
    public static authControllerChangePinCode(
requestBody: ChangePinCodeDto,
): CancelablePromise<ChangePinCodeEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/auth/change-pin-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check user' pin code
     * @param requestBody 
     * @returns CheckPinCodeEntity 
     * @throws ApiError
     */
    public static authControllerCheckPinCode(
requestBody: CheckPinCodeDto,
): CancelablePromise<CheckPinCodeEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/auth/check-pin-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns LoginEntity 
     * @throws ApiError
     */
    public static authControllerLogin(
requestBody: LoginDto,
): CancelablePromise<LoginEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get user' profile
     * @returns ProfileUserEntity 
     * @throws ApiError
     */
    public static authControllerGetProfileUser(): CancelablePromise<ProfileUserEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/auth/profile',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update user' profile
     * @param requestBody 
     * @returns UpdateProfileUserEntity 
     * @throws ApiError
     */
    public static authControllerUpdateProfile(
requestBody: UpdateProfileUserDto,
): CancelablePromise<UpdateProfileUserEntity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user-wallet/auth/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns LogoutUserEntity 
     * @throws ApiError
     */
    public static authControllerLogout(): CancelablePromise<LogoutUserEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/auth/logout',
        });
    }

}
