import { useQuery } from '@tanstack/react-query';
import { GetListPointTransactionEntity, UserWalletTransactionService } from 'services/openapi';

import { PointQueryKeys } from './constants';
import { isEmpty } from 'lodash';

type GetListTransPointDTO = {
  transactionStatus: 'Tất cả' | 'Thành công' | 'Thất bại' | 'Đã hủy' | 'Đang chờ hủy';
  fromTime: string;
  toTime: string;
  excutedOnlySelf: boolean;
  transactionCode?: string;
  transactionType?: 'Tất cả' | 'Thanh toán hóa đơn-Tiền điện' | 'Nạp điểm' | 'Chuyển điểm' | 'Nhận điểm' | 'Hoàn điểm';
  fromUserSearchString?: string;
  toUserSearchString?: string;
  searchDetailContent?: string;
  page: string;
  pageSize: string;
};

export const useGetPointTransactionsQuery = (variables: GetListTransPointDTO) => {
  const queryKey = [PointQueryKeys.GET_POINT_TRANSACTIONS, variables];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetListPointTransactionEntity,
    Error
  >({
    queryKey,
    queryFn: () =>
      UserWalletTransactionService.transactionControllerGetListPointTrans(
        variables.transactionStatus,
        variables.fromTime?.trim(),
        variables.toTime?.trim(),
        variables.excutedOnlySelf,
        variables.transactionCode?.trim(),
        variables.transactionType,
        variables.fromUserSearchString?.trim(),
        variables.toUserSearchString?.trim(),
        variables.searchDetailContent?.trim(),
        variables.page,
        variables.pageSize,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !isEmpty(variables)
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
