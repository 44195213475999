import { AuthLayout } from "layouts";
import React, { lazy } from "react";

import { AuthRouteKeys } from "../enums";
import { AuthRouteObject } from "../types";

const LoginPage = lazy(() => import("pages/Login"));
const OTPPage = lazy(() => import("pages/Auth/OTP"));
const NewPasswordPage = lazy(() => import("pages/Auth/NewPassword"));

export const AuthRoutesConfig: AuthRouteObject = {
  [AuthRouteKeys.Root]: {
    path: AuthRouteKeys.Root,
    name: "route:auth.name",
    title: "route:auth.title",
    element: <AuthLayout />,
    roles: "*",
  },
  [AuthRouteKeys.Login]: {
    path: AuthRouteKeys.Login,
    name: "Đăng nhập",
    title: "Đăng nhập",
    element: <LoginPage />,
    roles: "*",
  },
  [AuthRouteKeys.OTP]: {
    path: AuthRouteKeys.OTP,
    name: "Quên mật khẩu",
    title: "Quên mật khẩu",
    element: <OTPPage />,
    roles: "*",
  },
  [AuthRouteKeys.NewPassword]: {
    path: AuthRouteKeys.NewPassword,
    name: "Tạo mật khẩu mới",
    title: "Tạo mật khẩu mới",
    element: <NewPasswordPage />,
    roles: "*",
  },
};
